var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
            title: "Cập nhật thông báo hệ thống",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateAppSend",
              attrs: {
                "label-width": "140px",
                model: _vm.updateAppSend,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Tiêu đề", prop: "sendTitle" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tiêu đề" },
                              model: {
                                value: _vm.updateAppSend.sendTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.updateAppSend, "sendTitle", $$v)
                                },
                                expression: "updateAppSend.sendTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Nội dung", prop: "sendContent" } },
                          [
                            _c("el-input", {
                              attrs: {
                                rows: 15,
                                type: "textarea",
                                placeholder: "Nhập nội dung...",
                              },
                              model: {
                                value: _vm.updateAppSend.sendContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.updateAppSend,
                                    "sendContent",
                                    $$v
                                  )
                                },
                                expression: "updateAppSend.sendContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Chọn đại lý", prop: "idAgent" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "button-left",
                                attrs: {
                                  disabled: "",
                                  placeholder: "-Chọn đại lý-",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.updateAppSend.idAgent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.updateAppSend, "idAgent", $$v)
                                  },
                                  expression: "updateAppSend.idAgent",
                                },
                              },
                              _vm._l(_vm.agentListSearch, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.agentName,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Chọn trường", prop: "idSchool" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "button-left",
                                attrs: {
                                  disabled: "",
                                  placeholder: "-Chọn trường-",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.updateAppSend.idSchool,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.updateAppSend, "idSchool", $$v)
                                  },
                                  expression: "updateAppSend.idSchool",
                                },
                              },
                              _vm._l(_vm.schoolListSearch, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.schoolName,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Đối tượng nhận", prop: "appType" },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { disabled: "", size: "mini" },
                                model: {
                                  value: _vm.updateAppSend.appTypeArr,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateAppSend,
                                      "appTypeArr",
                                      $$v
                                    )
                                  },
                                  expression: "updateAppSend.appTypeArr",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "plus" } },
                                  [_vm._v("App Plus")]
                                ),
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "teacher" } },
                                  [_vm._v("App Teacher")]
                                ),
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "onekids" } },
                                  [_vm._v("App OneKids")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "File đính kèm", prop: "" } },
                          [
                            _c("el-upload", {
                              ref: "upload",
                              attrs: {
                                action: "",
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                multiple: "",
                                "auto-upload": false,
                                "on-change": _vm.toggleUpload,
                                limit: 5,
                                "on-exceed": _vm.handleExceed,
                                "file-list":
                                  _vm.updateAppSend
                                    .fileAttachAppSendResponseList,
                                "on-preview": _vm.handleFileRequest,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("updateAppSend")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("updateAppSend")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }