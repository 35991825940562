var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Nội dung thông báo",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hr" }, [_c("hr")]),
      _c("div", { staticClass: "table-content row-data" }, [
        _c("div", { staticClass: "content" }, [
          _c("span", [_vm._v(_vm._s(_vm.sendContentNotify))]),
        ]),
        _c(
          "div",
          { staticClass: "hasagi" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "danger", icon: "el-icon-circle-close" },
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              },
              [_vm._v("Đóng")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }