var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
            title: "Tạo thông báo hệ thống",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createAppSend",
              attrs: {
                "label-width": "140px",
                model: _vm.createAppSend,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Tiêu đề", prop: "sendTitle" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tiêu đề" },
                              model: {
                                value: _vm.createAppSend.sendTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createAppSend, "sendTitle", $$v)
                                },
                                expression: "createAppSend.sendTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Nội dung", prop: "sendContent" } },
                          [
                            _c("el-input", {
                              attrs: {
                                rows: 15,
                                type: "textarea",
                                placeholder: "Nhập nội dung",
                              },
                              model: {
                                value: _vm.createAppSend.sendContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createAppSend,
                                    "sendContent",
                                    $$v
                                  )
                                },
                                expression: "createAppSend.sendContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Chọn trường", prop: "idSchool" } },
                          [
                            _vm.checkIdSchool
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "button-left",
                                    attrs: {
                                      filterable: "",
                                      placeholder: "Chọn trường",
                                    },
                                    model: {
                                      value: _vm.createAppSend.idSchool,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.createAppSend,
                                          "idSchool",
                                          $$v
                                        )
                                      },
                                      expression: "createAppSend.idSchool",
                                    },
                                  },
                                  _vm._l(_vm.schoolList, function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          label: item.schoolName,
                                          value: item.id,
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v(_vm._s(item.id))]),
                                        _vm._v(" -\n                  "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.schoolName)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Đối tượng nhận",
                              prop: "appTypeArr",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.createAppSend.appTypeArr,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.createAppSend,
                                      "appTypeArr",
                                      $$v
                                    )
                                  },
                                  expression: "createAppSend.appTypeArr",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "plus" } },
                                  [_vm._v("App Plus")]
                                ),
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "teacher" } },
                                  [_vm._v("App Teacher")]
                                ),
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "parent" } },
                                  [_vm._v("App OneKids")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "File đính kèm",
                              prop: "attachFile",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  action: "",
                                  "on-remove": _vm.handleRemove,
                                  "before-remove": _vm.beforeRemove,
                                  "on-change": _vm.toggleUpload,
                                  "auto-upload": false,
                                  multiple: "",
                                  limit: 5,
                                  "on-exceed": _vm.handleExceed,
                                  "file-list": _vm.fileList,
                                  "on-preview": _vm.handleFileRequest,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "mini", type: "primary" } },
                                  [_vm._v("Tải lên")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("createAppSend")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("createAppSend")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }